import React from 'react'
import icon from 'images/icon.png'
import { useHistory } from 'react-router'

export default function Failed() {
    const history = useHistory()

    const onRetryClick = () => {
        history.push('/')
    }
    return (
        <div className="bg-white dark:bg-black h-full w-full flex flex-1 items-center">
            <div className="flex flex-1 flex-col items-center w-4/5">
                <span className="mt-2 ml-3 mr-3 text-xl text-black dark:text-white text-center">
                    ONTARIO COVID QR PASS GENERATOR For Apple Wallet
                </span>
                <img className="mt-5 w-1/4" src={icon} />
                <span className="text-black dark:text-white mt-5 text-center ml-3 mr-3">
                    Please select valid pdf downloaded from{' '}
                    <a
                        href="https://covid19.ontariohealth.ca"
                        className="text-blue-primary"
                    >
                        Ontario COVID-19 vaccination portal
                    </a>
                </span>
                <button
                    className="mt-5 h-11 w-4/5 rounded-sm text-white bg-green-verify"
                    onClick={onRetryClick}
                >
                    Retry
                </button>
            </div>
        </div>
    )
}
