import React from 'react'
import icon from 'images/icon.png'
import { isBrowser, isIOS } from 'Utils/userAgent'
import { useHistory } from 'react-router'
import Modal from 'Utils/Modal'
import Loader from 'Utils/Loader'

export default function Main() {
    const [iOS] = React.useState(isIOS())
    const [browser] = React.useState(isBrowser())
    const [isUploading, setUploading] = React.useState(false)
    const history = useHistory()

    if (!iOS) {
        history.replace('/invalid')
    }

    if (!browser) {
        history.replace('/invalid')
    }

    const onSubmit = () => {
        setUploading(true)
    }

    return (
        <div className="bg-white dark:bg-black h-full w-full flex flex-1 items-center">
            {isUploading && (
                <Modal>
                    <Loader color="#39B549" className="w-20 h-20" />
                </Modal>
            )}
            <div className="flex flex-1 flex-col items-center w-4/5">
                <span className="mt-2 ml-3 mr-3 text-xl text-black dark:text-white text-center">
                    ONTARIO COVID QR PASS GENERATOR For Apple Wallet
                </span>
                <img className="mt-5 w-1/4" src={icon} />
                <span className="text-black dark:text-white mt-5 text-center ml-3 mr-3">
                    Open this page in{' '}
                    <b className="text-blue-primary">Safari</b>(important) on
                    your iPhone. Upload the pdf file downloaded from{' '}
                    <a
                        href="https://covid19.ontariohealth.ca"
                        className="text-blue-primary"
                    >
                        Ontario COVID-19 vaccination portal
                    </a>
                    . You can add your COVID QR Pass to your Apple Wallet.
                </span>
                <form
                    className="mt-20 flex flex-col w-4/5 items-center"
                    method="POST"
                    action="https://herald-covid-pass.herokuapp.com/generate"
                    encType="multipart/form-data"
                    onSubmit={onSubmit}
                >
                    <input
                        type="file"
                        name="pdf"
                        accept="application/pdf"
                        className="w-full text-black dark:text-white w-full"
                    />
                    <input
                        type="submit"
                        className="mt-5 h-11 w-4/5 rounded-sm text-white bg-green-verify"
                    />
                </form>
                <span className="mt-5 text-black dark:text-white">
                    Sponsored by{' '}
                    <a
                        href="https://www.schoool.me/about/en/"
                        className="text-blue-primary"
                    >
                        SCHOOOL Inc.
                    </a>{' '}
                </span>
            </div>
        </div>
    )
}
