import React from 'react'
import cn from 'classnames'

type Props = {
    children: React.ReactNode
    onClose?(): void
    className?: string
}

export default function Modal({
    children,
    onClose,
    className,
    ...props
}: Props) {
    return (
        <div
            className="fixed top-0 left-0 right-0 bottom-0 z-50 flex-center flex flex-1 items-center justify-center bg-overlay-light dark:bg-overlay-dark"
            onClick={onClose}
            {...props}
        >
            {children}
        </div>
    )
}
