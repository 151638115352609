export function isIOS() {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone|ipod|ipad/.test(userAgent)
}

export function isBrowser() {
    if (isIOS()) {
        const userAgent = window.navigator.userAgent.toLowerCase()
        return /safari/.test(userAgent)
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value = window.navigator.standalone
    return !value
}
