import React from 'react'
import { Switch, Route, Redirect, HashRouter } from 'react-router-dom'
import Main from 'Pages/Main'
import Invalid from 'Pages/Invalid'
import Failed from 'Pages/Failed'

export default function App() {
    return (
        <HashRouter>
            <Switch>
                <Route path="/" exact component={Main} />
                <Route path="/invalid" component={Invalid} />
                <Route path="/failed" component={Failed} />
                <Redirect to="/" />
            </Switch>
        </HashRouter>
    )
}
